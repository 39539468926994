import { useEffect } from "react";
import { TASK_ACTIONS, DETAILS_VIEW_TYPE } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { taskAction } from "../mytasks/action/taskAction.action";
import {
    navigateBetweenProcessTasks,
} from "../mytasks/action/navigateBetweenTasks.action";
import { TaskDetailActions } from "../mytasks/AvailableActions.class";
import {
    getProcessAndHistoryComments,
    getProcessByUID,
    getTaskCommentsData,
    handleAddProcessAndHistoryComments,
    handleAddProcessAndHistoryDraftComments,
    removeSelectedDocument,
} from "../taskdetails-remake/taskDetails.action";
import { formatTaskDetailsTopBanner } from "utils/valueFormatter.function";
import { handleNPKeyNavigation } from "utils/keyPressHandler.function";
import _ from "lodash";
import { preventProcessReload } from "../processoverview/processOverview.action";
import TaskDetails from "components/taskdetails-remake/TaskDetails.component";
import { PATH_TO_PROCESS_OVERVIEW } from "components/router/paths.js";
import { launchDarkly } from "utils/launchDarkly";
import * as reduxSelectors from "components/store/application.reducers.js";


const TaskDetailMasterLayerByUID = (props: any) => {

    const tasks = useSelector(reduxSelectors.getProcessOverviewTasks);
    const task = useSelector(reduxSelectors.getActiveTask);
    const taskDetails = useSelector(reduxSelectors.getTaskDetails);
    const attachments = useSelector(reduxSelectors.getTaskAttachments);
    const loadingStatus = useSelector(reduxSelectors.getAttachmentsLoadingStatus);
    const useDraftComments = launchDarkly.userClient && launchDarkly.userClient.variation("fe-draft-comments", false);
    const dispatch = useDispatch();

    /*
     * default view is the attachemnts page so we need to get the attachemnts first
     * */
    useEffect(() => {
        // detail of current task is also in the store (use #getTaskDetailFromStore), but without for example the attachments, so we have to load it again
        requestData(props.match.params.uid);
        dispatch(removeSelectedDocument());
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    //this is needed to reload data at navigate between tasks with ctrl+alt+n
    useEffect(() => {
        requestData(props.match.params.uid);
    }, [props.match.params.uid]);


    const taskNavigation = (uid: string, isForward: boolean) => {
        props.history(dispatch(navigateBetweenProcessTasks(uid, isForward)));
    };

    const handleKeyPress = (e) => {
        handleNPKeyNavigation(e, props.match.params.uid, taskNavigation);
    };

    const requestData = (uid: string) => {
        return dispatch(getProcessByUID(uid));
    };

    const navigateBack = () => {
        /*before going back to the Process overview set the prevent data reload to true
            - we need to scroll position to remain in place
        */
        preventProcessReload(true);
    };

    const getActionsForTask = (navigation) => {
        let activeActions = new TaskDetailActions();

        // add email
        activeActions.enable(TASK_ACTIONS.EMAIL);

        //check if move actions are available
        if (navigation.currentTask !== navigation.total) {
            //enable move to next
            activeActions.enable(TASK_ACTIONS.NEXT_TASK);
        }
        if (navigation.currentTask !== 1) {
            activeActions.enable(TASK_ACTIONS.PREVIOUS_TASK);
        }
        return activeActions;
    };

    const getNavigation = () => {
        //fill in all the data found in the task
        let response = formatTaskDetailsTopBanner(task, true);

        response.companyName = task?.companyName;
        //task number and number of selected tasks still need to be computed
        response.currentTask = _.findIndex(tasks.allTasks, (x => {
            // @ts-ignore
            return x.key === task?.key;
        })) + 1;
        response.total = tasks.allTasks ? tasks.allTasks.length: 0;
        return response;
    };

    const dispatchTaskAction = (type: any, taskIds = [], options = {}, navigate = () => {
    }) => {
        dispatch(taskAction(type, taskIds, options, navigate));
    }

    const dispatchHandleAddProcessAndHistoryDraftComments = (taskKey: string, comment: string, documentUid: string, uid:string) => {
        dispatch(handleAddProcessAndHistoryDraftComments(taskKey, comment, documentUid, uid));
    }
    const dispatchHandleAddProcessAndHistoryComments = (taskKey:string, comment: string) => {
        dispatch(handleAddProcessAndHistoryComments(taskKey, comment));
    }

    const dispatchGetProcessAndHistoryComments = (uid: string, skipLoading = false) => {
        dispatch(getProcessAndHistoryComments(uid, skipLoading));
    }

    const dispatchGetTaskCommentsData = (taskId: string, skipLoading = false) => {
        dispatch(getTaskCommentsData(taskId, skipLoading));
    }

    const navigationData = getNavigation();
    const  activeActions = getActionsForTask(navigationData);
    const params = {
        taskId: task?.key,
        uid: task?.uid,
    };

    return (
        <div>
            {!task ?
                <div>
                    <span className="appicon loading" />
                </div> :
                // @ts-ignore
                <TaskDetails params={params}
                             task={task}
                             taskAction={dispatchTaskAction}
                             attachments={attachments}
                             loadingStatus={loadingStatus}
                             taskDetails={taskDetails}
                             navigation={navigationData}
                             route={props.route}
                             navigateBetweenTasks={taskNavigation}
                             activeActions={activeActions}
                             navigateBack={navigateBack}
                             navigateBackPath={PATH_TO_PROCESS_OVERVIEW}
                             isHistory={true}
                             type={DETAILS_VIEW_TYPE.PROCESS}
                             routerBlocker={props.blocker}
                             allowActionlessComments={taskDetails.allowExtraComments}
                             addCommentHandler={useDraftComments ? dispatchHandleAddProcessAndHistoryDraftComments : dispatchHandleAddProcessAndHistoryComments}
                             getCommentsHandler={useDraftComments ? dispatchGetProcessAndHistoryComments : dispatchGetTaskCommentsData}
                />
            }
        </div>
    );
}

export default TaskDetailMasterLayerByUID;
